import * as React from 'react'
import { graphql } from 'gatsby'

import get from 'lodash/get'
import { ContentfulTemplateCaseStudy } from '../data/case-study'
import {
  Header,
  VideoSection,
  BodyContent,
  ContinueReading,
  VideoPlayer,
  VideoCard,
  AuthGate,
} from '../components/case-study'
import { SEO } from '../components/seo'
import { DFBIAuthProvider, DFBIAuthContext } from '../context/dfbi-auth'
import styles from './case-study.module.scss'

interface Props {
  data: {
    current: ContentfulTemplateCaseStudy
    next: ContentfulTemplateCaseStudy
    previous: ContentfulTemplateCaseStudy
    related: {
      nodes: ContentfulTemplateCaseStudy[]
    }
  }
}

const DFBI_AUTH_MAILCHIMP_INTERESTS = ['fe3396b777']

export function PageWithAuth({
  data: { next, previous, current, related },
}: Props) {
  const ctx = React.useContext(DFBIAuthContext)
  return (
    <>
      <article>
        <SEO
          title={current.title}
          image={get(current.listImage, 'fluid.src', null)}
          description={current.listDescription.listDescription}
        />
        <Header
          title={current.title}
          previous={'/business-impact/' + previous.slug}
          next={'/business-impact/' + next.slug}
        />
        <VideoSection
          player={({ active, setActive }) => (
            <VideoPlayer
              cover={current.videoCoverImage}
              youtubeId={VideoPlayer.YoutubeURLToId(current.videoUrl)}
              onRequestPlay={() => setActive(!active)}
              covered={!active}
            />
          )}
          details={() => (
            <VideoCard
              logo={{
                src: current.companyLogo.file.url,
                alt: current.companyName,
              }}
              button={current.downloadButtonCta}
              asset={get(current.downloadableAsset, 'file.url', null)}
              speaker={current.speakerName}
              company={current.companyName}
              date={current.date}
              tags={current.contentTags}
              source={current.contentSource}
              length={current.contentLength}
            />
          )}
        />
        {ctx.authorized ? (
          <>
            <BodyContent
              logo={{
                src: current.companyLogo.file.url,
                alt: current.companyName,
              }}
              heading={current.bodyContentHeading.bodyContentHeading}
              subheading={current.bodyContentSubheading}
              button={current.downloadButtonCta}
              asset={get(current.downloadableAsset, 'file.url', null)}
              image={{
                fluid: current.speakerPhoto.fluid,
                alt: current.speakerPhoto.description,
              }}
              caption={`${current.speakerName}—${current.speakerTitle}`}
            >
              {current.bodyContent.childMarkdownRemark.html}
            </BodyContent>
            <ContinueReading list={related.nodes} />
          </>
        ) : null}
      </article>
      {!ctx.authorized ? (
        <AuthGate
          interests={DFBI_AUTH_MAILCHIMP_INTERESTS}
          className={styles.overlay}
          onComplete={({ response }) => {
            if (response.result === 'success') ctx.setAuthorized(true)
          }}
        />
      ) : null}
    </>
  )
}

export default function Page(props: Props) {
  return (
    <DFBIAuthProvider>
      <PageWithAuth {...props} />
    </DFBIAuthProvider>
  )
}

export const query = graphql`
  query CaseStudyPage($slug: String!, $next: String!, $previous: String!) {
    current: contentfulTemplateCaseStudy(slug: { eq: $slug }) {
      ...CaseStudyFields
    }
    next: contentfulTemplateCaseStudy(slug: { eq: $next }) {
      ...CaseStudyFields
    }
    previous: contentfulTemplateCaseStudy(slug: { eq: $previous }) {
      ...CaseStudyFields
    }
    related: allContentfulTemplateCaseStudy(
      limit: 8
      filter: { slug: { ne: $slug } }
      sort: { fields: [updatedAt], order: DESC }
    ) {
      nodes {
        ...CaseStudyFields
      }
    }
  }
`
