import React from 'react'
import { useCookies } from 'react-cookie'

export const DFBIAuthContext = React.createContext({})
export const DFBIAuthConsumer = DFBIAuthContext.Consumer

export function DFBIAuthProvider({ children }) {
  const state = useDFBIAuth()
  return (
    <DFBIAuthContext.Provider value={state}>
      {children}
    </DFBIAuthContext.Provider>
  )
}

const COOKIE_NAME = 'dfbi-cs'
const LIST_ID = '023e8e3c40'
const INTEREST_ID = 'fe3396b777'
const COOKIE_KEY = `${LIST_ID}.${INTEREST_ID}`

function useDFBIAuth() {
  const [cookies, setCookie, removeCookie] = useCookies([COOKIE_NAME])
  const cookie = cookies[COOKIE_NAME]
  if (cookie && !valid(cookie)) removeCookie(COOKIE_NAME)
  const [authorized, setAuthorized] = React.useState(valid(cookie))
  React.useEffect(
    () => (authorized ? create(setCookie) : removeCookie(COOKIE_NAME)),
    [authorized]
  )
  return { authorized, setAuthorized }
}

function create(set) {
  // const state = JSON.stringify({ authorized: true })
  const state = COOKIE_KEY
  const now = new Date().getTime()
  const year = 1000 * 60 * 60 * 24 * 365
  const options = {
    expires: new Date(now + year),
    path: '/business-impact',
    domain: location.hostname,
  }
  return set(COOKIE_NAME, state, options)
}

const valid = cookie => cookie === COOKIE_KEY
